import React, { useState } from "react";
import { MdArrowForwardIos, MdKeyboardArrowDown } from "react-icons/md";
import { cardStyle } from "../../styles";
import back from "../../images/faq-back.png";

const FaQ = () => {
  const [isActive, setIsActive] = useState(0);
  return (
    <div className="my-40 z-10 relative">
      <p className="pb-2 text-gray-500 text-base font-bold">ASK ANYTHING</p>
      <h1 className="text-6xl font-black pb-10">Frequently Asked Questions</h1>
      <div
        className="bg-blend-multiply rounded-lg p-5 w-full sm:w-full md:w-full lg:w-10/12 xl:w-3/4 2xl:w-3/4 mb-5"
        style={{
          ...cardStyle,
          background: `url(${back})`,
          backgroundSize: "contain",
        }}
      >
        <div className="flex-row flex justify-between items-center cursor-pointer">
          <h2
            className="text-lg font-black flex-grow"
            onClick={() => setIsActive(0)}
          >
            How does price discovery work on the Strip platform?
          </h2>
          <a className="pl-10" onClick={() => setIsActive(0)}>
            {isActive === 0 ? (
              <MdArrowForwardIos color="#ffffff" size={20} />
            ) : (
              <MdKeyboardArrowDown color="#ffffff" size={40} />
            )}
          </a>
        </div>
        {isActive == 0 && (
          <p className="font-thin text-base pt-5">
            The asset prices visible on Strip are directly fetched from the NFT
            marketplaces we have partnered with. We do not make any changes in
            those prices in either crypto or fiat terms.
          </p>
        )}
      </div>
      <div
        className="bg-blend-multiply rounded-lg p-5 w-full sm:w-full md:w-full lg:w-10/12	 xl:w-3/4 2xl:w-3/4 mb-5"
        style={{
          ...cardStyle,
          background: `url(${back})`,
          backgroundSize: "contain",
        }}
      >
        <div className="flex-row flex justify-between items-center cursor-pointer">
          <h2
            className="text-lg font-black flex-grow"
            onClick={() => setIsActive(1)}
          >
            I own an NFT and want to borrow against it. How do I go about it?
          </h2>
          <a className="pl-10" onClick={() => setIsActive(1)}>
            {isActive === 1 ? (
              <MdArrowForwardIos color="#ffffff" size={20} />
            ) : (
              <MdKeyboardArrowDown color="#ffffff" size={40} />
            )}
          </a>
        </div>
        {isActive === 1 && (
          <p className="font-thin text-base pt-5">
            An NFT owner can sign-in on the Strip platform using metamask which
            holds the NFTs. The user can then approve to lend it on the platform
            and wait for the lender to bid their proposed interest rate and time
            period. Once approved, the NFT owners aka borrowers receive the
            funds and NFT is transferred to the escrow contract. <br />
            <br />
            Another way is to pledge your asset in a pool of NFTs which is
            classified on the basis of artist of the art to determine the risk
            and assign value. The pool already has a predetermined time frame
            and interest rate. On maturity, the borrower has to repay the
            amount.
          </p>
        )}
      </div>
      <div
        className="bg-blend-multiply rounded-lg p-5 w-full sm:w-full md:w-full lg:w-10/12 xl:w-3/4 2xl:w-3/4 mb-5"
        style={{
          ...cardStyle,
          background: `url(${back})`,
          backgroundSize: "contain",
        }}
      >
        <div className="flex-row flex justify-between items-center cursor-pointer">
          <h2
            className="text-lg font-black flex-grow"
            onClick={() => setIsActive(2)}
          >
            I want to lend my stablecoins on the Strip platform. How do I go
            about it?
          </h2>
          <a className="pl-10" onClick={() => setIsActive(2)}>
            {isActive === 2 ? (
              <MdArrowForwardIos color="#ffffff" size={20} />
            ) : (
              <MdKeyboardArrowDown color="#ffffff" size={40} />
            )}
          </a>
        </div>
        {isActive === 2 && (
          <p className="font-thin text-base pt-5">
            As a lender, you can sign-in on the platform using metamask
            possessing stablecoins. Under the lend tab, one can see all the NFTs
            available with artists and owner details. You can bid for the ones
            that match your risk criteria. Upon the borrower’s acceptance of
            your bid, the determined amount will be transferred. <br />
            <br />
            Another approach is the pool approach where you can lend to the pool
            with a predetermined interest rate and time duration.
          </p>
        )}
      </div>
      <div
        className="bg-blend-multiply rounded-lg p-5 w-full sm:w-full md:w-full lg:w-10/12 xl:w-3/4 2xl:w-3/4 mb-5"
        style={{
          ...cardStyle,
          background: `url(${back})`,
          backgroundSize: "contain",
        }}
      >
        <div className="flex-row flex justify-between items-center cursor-pointer">
          <h2
            className="text-lg font-black flex-grow"
            onClick={() => setIsActive(3)}
          >
            How much does Strip charge as platform fees?
          </h2>
          <a className="pl-10" onClick={() => setIsActive(3)}>
            {isActive === 3 ? (
              <MdArrowForwardIos color="#ffffff" size={20} />
            ) : (
              <MdKeyboardArrowDown color="#ffffff" size={40} />
            )}
          </a>
        </div>
        {isActive === 3 && (
          <p className="font-thin text-base pt-5">
            Strip charges 1% of the asset value to facilitate the
            borrowing/lending. It also charges 10% from the interest borrower
            pays. In case of liquidation, the lender has to pay the 10% of the
            sale proceeds.
          </p>
        )}
      </div>
    </div>
  );
};

export default FaQ;
