import React from "react";
import bigCoin from "../../images/funds/bigcoin.png";
import block0 from "../../images/funds/block0.png";
import dci from "../../images/funds/dci.png";
import DHC from "../../images/funds/dhc.png";
import exnetwork from "../../images/funds/exnetwork.png";
import frontier from "../../images/funds/frontier.png";
import halvingsCapital from "../../images/funds/halvings.png";
import kryptos from "../../images/funds/kryptos.png";
import lancer from "../../images/funds/lancer.png";
import idCapital from "../../images/funds/ld-capital.png";
import mexc from "../../images/funds/mexc.png";
import newfield from "../../images/funds/newfield-fund.png";
import nothing from "../../images/funds/nothing.png";
import PVPLogo from "../../images/funds/pvp.png";
import rockstable from "../../images/funds/rockstable.png";
import shimaCapital from "../../images/funds/shima-capital.png";
import tenzor from "../../images/funds/tenzor.png";
import tokenova from "../../images/funds/tokenova.png";
import valhalla from "../../images/funds/valhalla.png";
import wave7 from "../../images/funds/wave7.png";
import zbs from "../../images/funds/zbs.png";

const investors = [
  tenzor,
  idCapital,
  exnetwork,
  lancer,
  kryptos,
  block0,
  nothing,
  mexc,
  newfield,
  shimaCapital,
  tokenova,
  PVPLogo,
  frontier,
  bigCoin,
  halvingsCapital,
  valhalla,
  wave7,
  zbs,
  dci,
  DHC,
];

const Investors = () => {
  return (
    <div className="pt-40 z-10">
      <div className="pb-10">
        <p className="pb-2 text-gray-500 text-base font-bold text-center">
          INVESTORS
        </p>
        <h1 className="text-6xl font-black text-center">
          Backed by Diamond hands
        </h1>
      </div>
      <div className="flex flex-row justify-between items-center py-10 flex-wrap">
        {investors.map((item) => (
          <div
            className="bg-blend-multiply rounded-lg w-2/6 sm:w-1/4 md:w-1/4 xl:w-1/5 2xl:w-1/5 flex justify-center pb-20"
            key={item.name}
          >
            <img src={item} className="h-14" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Investors;
