import React, { useState } from "react";
import Pager from "./Pager";
import logo from "../../images/logo.png";
import Menu from "../../images/Menu.png";
import menuRev from "../../images/menu-rev.png";
import { Link } from "gatsby";

// import { Link } from "gatsby";

const Header = ({ hideTeam }: any) => {
  const [isActive, setIsActive] = useState(false);

  const onHandleTeam = () => {
    window?.scrollTo({ top: 1900, behavior: "smooth" });
  };
  return (
    <header
      className=" bg-blend-multiply sticky top-0 z-30 bg-black py-2"
      style={{
        backdropFilter: "blur(86px)",
        background: "rgba(255, 255, 255, 0)",
        boxShadow: "inset 0px 0px 68px rgba(255, 255, 255, 0.0001)",
        WebkitBackdropFilter: "blur(86px)",
      }}
    >
      <Pager>
        <div className="flex flex-row justify-between items-center">
          <div className="py-2">
            <Link to="/">
              <img src={logo} className="h-12	" />
            </Link>
          </div>
          <div className="md:hidden lg:hidden xl:hidden 2xl:hidden">
            <a onClick={() => setIsActive(!isActive)}>
              <img src={isActive ? menuRev : Menu} />
            </a>
          </div>
          <div className="hidden md:block lg:block xl:block 2xl:block w-7/12 lg:w-6/12">
            <div className="flex flex-row justify-between items-center">
              <Link to="/">
                <p className="text-base cursor-pointer">Home</p>
              </Link>
              <Link to="/roadmap">
                <p className="text-base cursor-pointer">Roadmap</p>
              </Link>
              <Link to="/how-it-works">
                <p className="text-base">How it works</p>
              </Link>
              {!hideTeam && (
                <a onClick={onHandleTeam} className="cursor-pointer">
                  <p className="text-base">Team</p>
                </a>
              )}
              <a
                href="https://stripfinance.gitbook.io/whitepaper/"
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-base">White Paper</p>
              </a>
              <a
                href="https://app.strip.finance/"
                className="rounded-lg px-4 py-2 special_btns text-blue-100 hover:bg-gray-600 duration-300"
                target="_blank"
                rel="noreferrer"
              >
                App
              </a>
            </div>
          </div>
        </div>
        {isActive && (
          <div className=" ease-linear flex flex-col justify-between items-start px-3">
            <Link to="/">
              <p className="text-lg cursor-pointer pt-3">Home</p>
            </Link>
            <Link to="/roadmap">
              <p className="text-lg cursor-pointer pt-3">Roadmap</p>
            </Link>
            <Link to="/how-it-works">
              <p className="text-lg pt-3">How it works</p>
            </Link>
            {!hideTeam && (
              <a onClick={onHandleTeam} className="cursor-pointer">
                <p className="text-lg pt-3">Team</p>
              </a>
            )}
            <a
              href="https://stripfinance.gitbook.io/whitepaper/"
              target="_blank"
              rel="noreferrer"
            >
              <p className="text-lg py-3">White Paper</p>
            </a>
            <a
              href="https://app.strip.finance/"
              className="rounded-lg px-4 py-2 bg-gradient-to-r from-green-400 to-gray-500 text-blue-100 hover:bg-blue-600 duration-300"
              target="_blank"
              rel="noreferrer"
            >
              App
            </a>
          </div>
        )}
      </Pager>
    </header>
  );
};

export default Header;
