import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ hideTeam, children }: any) => {
  return (
    <div className="bg-black text-white">
      <Header hideTeam={hideTeam} />
      <div className="overflow-hidden">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
