import React from "react";
import mexc from "../../images/mexc.png";
import pancake from "../../images/pancake.png";

const Hero = () => {
  return (
    <div>
      <div className="flex justify-center items-center relative z-10 py-20 lg:py-40 xl:py-40 2xl:py-40 px-2 lg:px-40 xl:px-40 2xl:px-40">
        <div>
          <h1 className="font-black text-6xl sm:text-7xl md:text-7xl lg:text-8xl xl:text-8xl 2xl:text-8xl text-center">
            The Financial Layer of the Metaverse
          </h1>
          <br />
          <div className="text-center">
            Decentralised platform for easing liquidity in the NFT space
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center flex-col font-bold text-2xl z-50 mt-28 md:mt-0">
        <div className="md:w-2/12 w-8/12 text-center z-50">
          $STRIP Trading is live on
        </div>
        <div className="md:flex mt-8">
          <a
            href="https://www.mexc.com/exchange/STRIP_USDT"
            className="special_btns px-6 h-16 text-base font-medium flex justify-center items-center md:mr-8 mb-4 md:mb-0"
          >
            <img src={mexc} alt="mexc" className="swaps-img" />
          </a>
          <a
            href="https://pancakeswap.finance/swap?outputCurrency=0x0fe178b9a471b3698cb6fcb4625df9a756a2c55c"
            className="special_btns px-6 h-16 text-base font-medium flex justify-center items-center"
          >
            <img src={pancake} alt="pancake" className="swaps-img" />
          </a>
          {/* <img src={mexc} alt="mexc" className="swaps-img mr-8" />
          <img src={pancake} alt="pancake" className="swaps-img" /> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
