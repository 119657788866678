import React from "react";
import { cardStyle } from "../../styles";
import siddharth from "../../images/team/siddharth.png";
import jaynti from "../../images/team/jaynti.png";
import tamar from "../../images/team/tamar.png";
import yida from "../../images/team/yida.png";

const teamData = [
  {
    name: "Siddharth Menon",
    subtitle: "CO-FOUNDER & COO, WAZIRX",
    pic: siddharth,
  },
  {
    name: "Jaynti Kanani",
    subtitle: "CO-FOUNDER & CEO, POLYGON",
    pic: jaynti,
  },
  {
    name: "Tamar M",
    subtitle: "ECOSYSTEM & GROWTH AT SOLANA",
    pic: tamar,
  },
  {
    name: "Yida Gao",
    subtitle: "General Partner at Shima Capital",
    pic: yida,
  },
];

const Advisors = () => {
  return (
    <div className="pt-10">
      <h1 className="text-3xl font-black text-center mb-3">Advisors</h1>
      <div className="flex flex-row justify-between items-start py-10 flex-wrap">
        {teamData.map((item) => (
          <div
            className=" w-3/6 sm:w-2/6 md:w-3/12 lg:w-3/12 xl:w-3/12 2xl:w-3/12 flex flex-col justify-center items-center pb-8"
            key={item.name}
          >
            <div className="h-28 w-28 rounded-full mb-3" style={cardStyle}>
              <img src={item.pic} />
            </div>
            <h2 className="text-lg font-black">{item.name}</h2>
            <p className="font-bold text-base text-gray-500 px-10 text-center py-2 uppercase">
              {item.subtitle}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Advisors;
