import React from "react";
import { truncate } from "lodash";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { cardStyle } from "../../styles";
import btcmanager from "../../images/media/btcmanager.png";
import ct from "../../images/media/ct.png";
import defient from "../../images/media/defient.png";
import fp from "../../images/media/fp.png";
import inc42 from "../../images/media/inc42.png";
import newsbtc from "../../images/media/newsbtc.png";
import yn from "../../images/media/yn.png";
import yourstory from "../../images/media/yourstory.png";

const mentions = [
  {
    title:
      "Exclusive: NFT Lending Marketplace Strip Finance Raises Funds From MEXC Exchange, Others",
    description:
      "The seed and private round of INR 11 Cr saw participation from Old Fashion Research, Nothing Research, Tenzor capital, among others as well as Solana’s Tamar Menteshashvili, Ankr’s Ryan Fang and Marco Robustelli, and Persistence’s Tushar Aggarwal",
    link: "https://inc42.com/buzz/exclusive-nft-lending-marketplace-strip-finance-raises-private-round-from-mexc-exchange-others/",
    mediaName: "INC42",
    mediaImage: inc42,
  },
  {
    title:
      "Multiple VCs back Strip Finance in $1.5M to build NFT collateralization platform",
    description:
      "Strip Finance recently closed a $1.5 million funding round from Old Fashion Research, Nothing Research, Tenzor capital, Exnetwork Capital, Valhalla capital, Block0, Shima Capital, Lancer Capital, MEXC Global Exchange, Kryptos Research, The NewField Fund, ZBS Capital, J10M Capital and Wave 7, and others who participated in the round.",
    link: "https://cointelegraph.com/press-releases/multiple-vcs-back-strip-finance-in-15m-to-build-nft-collateralization-platform",
    mediaName: "Cointelegraph",
    mediaImage: ct,
  },
  {
    title:
      "Don’t Sell, Collateralise; Says NFT Liquidity Platform Strip Finance",
    description:
      "Strip Finance, a Collateralised NFT & DeFi Liquidity Protocol, is putting NFTs for better use by collateralising them for stablecoins and providing greater liquidity. A next-generation NFT platform called Strip Finance is making it possible to collateralise Non-Fungible tokens while still maintaining ownership of the assets, taking DeFi and NFTs to a whole new dimension.",
    link: "https://www.newsbtc.com/news/company/dont-sell-collateralise-says-nft-liquidity-platform-strip-finance/",
    mediaName: "News BTC",
    mediaImage: newsbtc,
  },
  {
    title:
      "Strip Finance raises Rs 3.5 Cr in seed round to build collateralisation platform for NFTs",
    description:
      "With investment from Old Fashion Research, Nothing Research, Tenzor Capital, Block0, Shima Capital, Lancer Capital, and J10M Capital, the startup is looking to build its NFT platform and launch it on Binance Smart Chain.",
    link: "https://yourstory.com/2021/09/strip-finance-nft-platform-funding/amp",
    mediaName: "Your Story",
    mediaImage: yourstory,
  },
  {
    title:
      "NFT-backed Lending Market Surges with $1M-Plus Loans and 20% Interest Rates",
    description:
      "A raft of players are jockeying for position in the burgeoning market. Key platforms include Strip Finance, Banksea Finance, Drops DAO, Unbanked, which launched on Nov. 1, all of which provide some form of NFT-backed loan service as users look for liquidity while holding onto their assets.",
    link: "https://thedefiant.io/nft-loans-collateral-peer-to-peer-ethereum/",
    mediaName: "The Defiant",
    mediaImage: defient,
  },
  {
    title:
      "Leading Incubator, Starter to Host Token Sale for the Financial Layer Protocol of the Metaverse, StripFinance",
    description:
      "As the Non-fungible token (NFT) space continues to vastly expand on the blockchain, hundreds of thousands of creators and enthusiasts interact daily, creating millions of dollars in value within the NFT space. The growing transfer and value of NFTs has created a liquidity challenge in the market that will be solved by StripFinance. The protocol will allow users to provide loans against the value of NFTs as collateral and unlock millions of liquidity currently locked away in ‘Blue Chip NFTs",
    link: "https://finance.yahoo.com/news/leading-incubator-starter-host-token-130000350.html",
    mediaName: "Yahoo Finance",
    mediaImage: yn,
  },
  {
    title: "Strip Finance is Taking the Metaverse by Storm",
    description:
      "Strip Finance with its decentralised peer-to-peer marketplace for NFT collateralisation is creating secondary markets for the NFT ecosystem. Strip Finance primarily allows users to lend their NFTs for stablecoins allowing them to attain liquidity without selling or leveraging the value of holdings to mint more NFTs. Furthermore, Strip Finance allows lenders to earn interest on the platform and also give a chance to acquire defaulted NFTs at discount prices.",
    link: "https://btcmanager.com/strip-finance-taking-metaverse/",
    mediaName: "BTC Manager",
    mediaImage: btcmanager,
  },
  {
    title:
      "Leading Incubator, Starter to Host Token Sale for the Financial Layer Protocol of the Metaverse, StripFinance",
    description:
      "StripFinance will be offering something we have yet to see normalized in the NFT space: NFT collateralization. Their DeFi protocol will add another staple to NFT utility by allowing lenders and borrowers to use NFTs to conduct transactions and provide additional utility, “said Lionel Iruk, special counsel at Starter. “It’s great to be a part of StripFinance’s process to see how the protocol will further push the boundaries of the blockchain!",
    link: "https://financialpost.com/globe-newswire/leading-incubator-starter-to-host-token-sale-for-the-financial-layer-protocol-of-the-metaverse-stripfinance",
    mediaName: "Financial Post",
    mediaImage: fp,
  },
];

const Problems = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="mt-40 relative z-10">
      <div className="mb-10">
        <p className="pb-2 text-gray-500 text-base font-bold text-center uppercase">
          what they talk about us
        </p>
        <h1 className="text-6xl font-black text-center">Media Mentions</h1>
      </div>
      <Slider {...settings}>
        {mentions.map((item) => (
          <div
            key={item.link}
            className="w-full sm:w-2/6 md:w-2/6 lg:w-2/6 xl:w-2/6 2xl:w-2/6 mb-5"
            style={cardStyle}
          >
            <div
              className="bg-blend-multiply p-5 rounded-lg mx-5"
              style={cardStyle}
            >
              <div className="flex flex-row justify-start items-center">
                <img src={item.mediaImage} />
                <p
                  className="text-xl font-bold ml-4"
                  style={{ color: "#00F5A0" }}
                >
                  {item.mediaName}
                </p>
              </div>
              <h2 className="text-lg font-black pt-5 pb-5">{item.title}</h2>
              <p className="font-thin text-xs pb-3">
                {truncate(item.description, { length: 200 })}
              </p>
              <a
                className="text-sm pt-3 cursor-pointer hover:text-green-700 font-semibold"
                href={item.link}
                target="_blank"
                rel="noreferrer"
              >
                READ MORE
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Problems;
