import React from "react";
import { AiOutlineTwitter, AiFillGithub } from "react-icons/ai";
import { SiMedium } from "react-icons/si";
import { ImLinkedin2 } from "react-icons/im";
import { FiSend } from "react-icons/fi";
import Pager from "./Pager";

import logo from "../../images/logo.png";

const Header = () => {
  return (
    <footer
      className="bg-black pb-10 z-50"
      style={{
        backdropFilter: "blur(86px)",
        background: "rgba(255, 255, 255, 0)",
        boxShadow: "inset 0px 0px 68px rgba(255, 255, 255, 0.0001)",
        WebkitBackdropFilter: "blur(86px)",
      }}
    >
      <Pager>
        <div className="block md:hidden sm:hidden lg:hidden xl:hidden 2xl:hidden">
          <a className="text-center">
            <p className="text-base font-semibold">FOLLOW US</p>
          </a>

          <div className="flex flex-row justify-between items-center pt-5">
            <a
              className="py-2 px-4 cursor-pointer"
              href="https://twitter.com/StripFinance"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineTwitter size={25} color="#b3b3b4" />
            </a>
            <a
              className="py-2 px-4 cursor-pointer"
              href="https://medium.com/stripfinance"
              target="_blank"
              rel="noreferrer"
            >
              <SiMedium size={25} color="#b3b3b4" />
            </a>

            <a
              className="py-2 px-4 cursor-pointer"
              href="https://www.linkedin.com/company/stripfinance"
              target="_blank"
              rel="noreferrer"
            >
              <ImLinkedin2 size={25} color="#b3b3b4" />
            </a>
            <a
              className="py-2 px-4 cursor-pointer"
              href="https://t.me/stripfinance"
              target="_blank"
              rel="noreferrer"
            >
              <FiSend size={22} color="#b3b3b4" />
            </a>
            {/* <a className="py-2 px-4 cursor-pointer" href="" target="_blank">
              <AiFillGithub size={25} color="#b3b3b4" />
            </a> */}
          </div>
          <div className="flex flex-row justify-between items-center mt-5">
            <a className=" cursor-pointer text-base py-2 px-4 font-normal">
              Terms & Conditions
            </a>
            <a>
              <p className=" cursor-pointer text-base py-2 px-4 font-normal">
                Privacy Policy
              </p>
            </a>
          </div>
          <div className="cursor-pointer flex justify-center mt-5">
            <img src={logo} className="h-12	" />
          </div>
          <p className="text-base font-semibold text-center pt-5">
            Your Preferred NFT & DeFi <br /> Liquidity Platform.
          </p>
        </div>

        <div className="hidden md:block lg:block xl:block 2xl:block">
          <div className="flex flex-row justify-between items-center py-5">
            <div className="cursor-pointer">
              <img src={logo} className="h-12	" />
            </div>
            <div className="flex flex-row justify-between items-center">
              <a>
                <p className="text-base font-semibold">FOLLOW US</p>
              </a>
              <a
                className="py-2 px-4 cursor-pointer"
                href="https://twitter.com/StripFinance"
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineTwitter size={25} color="#b3b3b4" />
              </a>
              <a
                className="py-2 px-4 cursor-pointer"
                href="https://medium.com/stripfinance"
                target="_blank"
                rel="noreferrer"
              >
                <SiMedium size={25} color="#b3b3b4" />
              </a>

              <a
                className="py-2 px-4 cursor-pointer"
                href="https://www.linkedin.com/company/stripfinance"
                target="_blank"
                rel="noreferrer"
              >
                <ImLinkedin2 size={25} color="#b3b3b4" />
              </a>
              <a
                className="py-2 px-4 cursor-pointer"
                href="https://t.me/stripfinance"
                target="_blank"
                rel="noreferrer"
              >
                <FiSend size={22} color="#b3b3b4" />
              </a>
              {/* <a className="py-2 px-4 cursor-pointer" href="" target="_blank">
                <AiFillGithub size={25} color="#b3b3b4" />
              </a> */}
            </div>
          </div>

          <div className="flex flex-row justify-between items-center">
            <div className="flex-grow">
              <p className="text-base font-semibold">
                Your Preferred NFT & DeFi Liquidity Platform.
              </p>
            </div>
            <a
              className=" cursor-pointer text-base py-2 px-4 font-normal"
              href="#"
            >
              Terms & Conditions
            </a>
            <a href="#">
              <p className=" cursor-pointer text-base py-2 px-4 font-normal">
                Privacy Policy
              </p>
            </a>
          </div>
        </div>
      </Pager>
    </footer>
  );
};

export default Header;
