import React from "react";
import { cardStyle } from "../../styles";
import wallet from "../../images/Wallet.png";
import activity from "../../images/Activity.png";
import market from "../../images/Bag.png";

const Problems = () => {
  return (
    <div className="mt-40 relative z-10">
      <div>
        <p className="pb-2 text-gray-500 text-base font-bold text-center">
          SOLUTION
        </p>
        <h1 className="text-6xl font-black text-center">Our Offerings</h1>
      </div>
      <div className="flex flex-row justify-between items-start py-10 flex-wrap sm:flex-nowrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap 2xl:flex-nowrap">
        <div
          className="bg-blend-multiply rounded-lg w-full sm:w-2/6 md:w-2/6 lg:w-2/6 xl:w-2/6 2xl:w-2/6 p-5 mb-5 mx-5"
          style={cardStyle}
        >
          <img src={wallet} className="h-14" />
          <h2 className="text-lg font-black pt-5">Access to Capital </h2>
          <p className="font-thin text-base">
            Enabling NFT collectors to borrow against their assets as collateral
            on fair interest rates
          </p>
        </div>
        <div
          className="bg-blend-multiply rounded-lg w-full sm:w-2/6 md:w-2/6 lg:w-2/6 xl:w-2/6 2xl:w-2/6 p-5 mb-5 mx-5"
          style={cardStyle}
        >
          <img src={activity} className="h-14" />
          <h2 className="text-lg font-black pt-5">Bidding & Pools</h2>
          <p className="font-thin text-base">
            Allowing liquidity providers to participate either through direct
            bidding or pools
          </p>
        </div>
        <div
          className="bg-blend-multiply rounded-lg w-full sm:w-2/6 md:w-2/6 lg:w-2/6 xl:w-2/6 2xl:w-2/6 p-5 mb-5 mx-5"
          style={cardStyle}
        >
          <img src={market} className="h-14" />
          <h2 className="text-lg font-black pt-5">Marketplace</h2>
          <p className="font-thin text-base">
            Both lenders & borrowers can opt for either pool or P2P marketplace
          </p>
        </div>
      </div>
    </div>
  );
};

export default Problems;
