import * as React from "react";
import Advisors from "../components/home/Advisors";
import FaQ from "../components/home/FaQ";
import Hero from "../components/home/Hero";
import Investors from "../components/home/Investors";
import MediaMentions from "../components/home/MediaMentions";
import Partners from "../components/home/Partners";
import Problems from "../components/home/Problems";
import Solutions from "../components/home/Solutions";
import Team from "../components/home/Team";
import Layout from "../components/layouts";
import Pager from "../components/layouts/Pager";
import crystalHRight from "../images/background/bg-crystal-1.png";
import crystalHLeft from "../images/background/bg-crystal-2.png";
import crystalRight from "../images/background/bg-crystal-3.png";

import circle from "../images/background/circle-mobile.png";

import Ball from "../images/icons/eclipse1.svg";
import Ball2 from "../images/icons/eclipse.svg";
import ball from "../images/background/dot-mobile.png";
// markup
const IndexPage = () => {
  return (
    <Layout hideTeam={false}>
      <div className="relative">
        <div
          className="absolute left-0 hidden sm:block md:block  lg:block xl:block 2xl:block"
          style={{ top: "-5rem" }}
        >
          <img src={crystalHLeft} />
        </div>
        <div
          className="absolute hidden sm:block md:block  lg:block xl:block 2xl:block"
          style={{ top: "-10rem", right: "-40rem" }}
        >
          <img src={crystalHRight} />
        </div>
        <div
          className="absolute block sm:hidden md:hidden  lg:hidden xl:hidden 2xl:hidden"
          style={{ top: "18rem", right: "-11rem" }}
        >
          <img src={crystalHRight} />
        </div>
        <Pager>
          <Hero />
          <Problems />
        </Pager>
      </div>

      <div className="relative">
        <Pager>
          <Solutions />
        </Pager>
        <div className="absolute right-0" style={{ bottom: "8rem" }}>
          <Ball />
        </div>
        <div className="absolute left-0" style={{ bottom: "-5rem" }}>
          <Ball2 />
        </div>
        <div
          className="absolute right-0"
          style={{ top: "20rem", bottom: "0rem" }}
        >
          <img src={ball} />
        </div>
      </div>
      {/* <Pager>
        <Features />
      </Pager> */}
      <Pager>
        {/* <Team /> */}
        <Advisors />
      </Pager>
      <div className="relative">
        <Pager>
          <Investors />
        </Pager>
        <div
          className="absolute left-0"
          style={{ top: "50rem", bottom: "0rem" }}
        >
          <img src={circle} />
        </div>
      </div>

      <Pager>
        <MediaMentions />
      </Pager>
      <Pager>
        <Partners />
      </Pager>

      <div className="relative">
        <Pager>
          <FaQ />
        </Pager>
        <div
          className="absolute right-0 hidden sm:block md:block lg:block xl:block 2xl:block"
          style={{ bottom: "-25rem" }}
        >
          <img src={crystalRight} />
        </div>
        <div
          className="absolute right-0 block sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden"
          style={{ bottom: "-10rem" }}
        >
          <img src={crystalRight} />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
