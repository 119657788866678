import React from "react";
import close from "../../images/Close.png";

const Problems = () => {
  return (
    <div className="flex flex-row justify-center items-start  pt-96 flex-wrap lg:flex-nowrap xl:flex-nowrap 2xl:flex-nowrap">
      <div className="w-full lg:w-6/12 xl:w-6/12 2xl:w-6/12 lg:mr-20 xl:mr-20 2xl:mr-20">
        <p className="pb-2 text-gray-500 text-base font-bold">PROBLEM</p>
        <h1 className="text-6xl font-black">Liquidity of NFT</h1>
        <br />
        <p className="text-lg">
          Hundreds of thousands of creators and an even higher number of
          enthusiasts are engaged in creating Millions of dollars in value in
          the NFT space today.
        </p>
        <br />
        <p className="text-lg">
          With the ever-growing transfer and value of NFTs, it is creating a
          liquidity challenge in the market.
        </p>
      </div>
      <div className="w-full lg:w-6/12 xl:w-6/12 2xl:w-6/12 pt-10">
        <div className="pb-8">
          <div className="flex flex-row items-start">
            <img src={close} className="h-14" />
            <div>
              <h2 className="text-lg font-black">
                Locked Liquidity in NFT space
              </h2>
              <p className="font-thin text-base">
                Asset Ownership blocking Liquidity
              </p>
            </div>
          </div>
        </div>
        <div className="pb-8">
          <div className="flex flex-row justify-start items-start">
            <img src={close} className="h-14" />
            <div>
              <h2 className="text-lg font-black">
                Missing Aftermarket for NFTs
              </h2>

              <p className="font-thin text-base">
                No Alternative to primary trade platforms
              </p>
            </div>
          </div>
        </div>
        <div className="pb-8">
          <div className="flex flex-row items-start">
            <img src={close} className="h-14" />
            <div>
              <h2 className="text-lg font-black">Absence of Price Discovery</h2>
              <p className="font-thin text-base">
                Missing oracles for fair value assessment
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Problems;
